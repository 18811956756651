<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <h1 class="mb-8">{{ $t('account.resetPassword') }}</h1>

    <v-form>
      <c-input
        v-model="model.code"
        input-class="text-center"
        dir="ltr"
        maxlength="6"
        key="code"
        autofocus
        placeholder="- - - - - -"
        :label="$t('shared.code')"
        :rules="'required|numeric|digits:6'"
      />

      <c-input
        ref="password"
        v-model="model.newPassword"
        :label="$t('shared.newPassword')"
        :rules="'min:6'"
        dir="ltr"
        type="password"
      />

      <c-input
        v-model="model.newPasswordConfirm"
        dir="ltr"
        :label="$t('shared.confirmPassword')"
        :rules="`min:6|confirmed:${$t('shared.newPassword')}`"
        type="password"
      />
    </v-form>

    <div class="d-flex mb-8 align-center">
      <div v-if="counter" class="grey--text">
        <span>{{ timer }} {{ $t('shared.toResendAgain') }} </span>
      </div>

      <v-btn v-else color="secondary" small @click="getRecoveryCode">
        {{ $t('account.resendCode') }}
      </v-btn>

      <v-spacer></v-spacer>
      <a @click="emitReset"> {{ $t('shared.editMobile') }} </a>
    </div>

    <v-btn block color="success" @click="handleSubmit(changePassword)" :loading="progressing">
      {{ $t('account.changePassword') }}
    </v-btn>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'ResetPassword',
  props: {
    value: String
  },
  data() {
    return {
      progressing: false,
      counter: 120,
      timer: '2:00',
      model: {
        code: null,
        countryCode: null,
        newPassword: null,
        newPasswordConfirm: null,
        username: null
      }
    }
  },
  created() {
    this.initTimer()
  },
  methods: {
    emitReset() {
      this.$emit('reset')
    },
    initTimer() {
      this.counter = 120
      this.startTimer()
    },
    startTimer() {
      this.timeout = setInterval(() => {
        if (this.counter <= 0) {
          clearInterval(this.timeout)
        } else {
          this.counter -= 1
          const m = Math.floor(this.counter / 60)
          const s = this.counter % 60
          this.timer = `${m}:${s}`
        }
      }, 1000)
    },
    changePassword() {
      this.model.username = this.value.mobile || this.value.email
      this.progressing = true

      return this.$api.accountPub
        .resetPassword(this.model)
        .then((res) => {
          this.$showSuccess(this.$t('shared.passwordChanged'))
          this.$router.push('/login')
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.progressing = false
        })
    },
    getRecoveryCode() {
      this.progressing = true
      return this.$api.accountPub
        .forgotPassword(this.value)
        .then(() => {
          this.initTimer()
          this.$showSuccess(this.$t('account.recoveryCodeSendedToYourMobile'))
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.progressing = false
        })
    }
  }
}
</script>
